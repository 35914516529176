import { API_PATHS, createAxiosInstance } from '../constants';
import { IError, ISuccess } from '../types';
import { ILeadData } from './types';

export const createLeadRequest = async (
  lead: ILeadData,
): Promise<IError | ISuccess> => {
  const axiosInstance = createAxiosInstance();
  await axiosInstance.post(API_PATHS.landing.leads, lead);
  return { success: true };
};
