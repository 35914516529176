import React from 'react';
import { SEO } from 'components/SEO';
import { ILeadModalData, LeadModal } from 'containers/Modals/LeadModal';
import { MainBlock } from './MainBlock';
import { CoursesBlock } from './CoursesBlock';
import './styles.scss';

const TrialLessonPage = () => {
  const [leadModal, setLeadModal] = React.useState<ILeadModalData>({
    opened: false,
    courseId: undefined,
  });
  const onLeadModalClose = React.useCallback(() => {
    setLeadModal({
      opened: false,
      courseId: undefined,
    });
  }, []);
  const onLeadModalOpen = React.useCallback((courseId) => {
    setLeadModal({
      opened: true,
      courseId,
    });
  }, []);

  return (
    <main className="page trial-lesson-page">
      <SEO
        title="Пробное занятие"
        description="Запишитесь на пробное онлайн-занятие по: шахматам, математике, программированию для детей совершенно бесплатно"
      />
      <MainBlock />
      <CoursesBlock onLeadModalOpen={onLeadModalOpen} />
      <LeadModal
        opened={leadModal.opened}
        courseId={leadModal.courseId}
        onClose={onLeadModalClose}
      />
    </main>
  );
};

export default TrialLessonPage;
