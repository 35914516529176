import { CourseIds } from 'constants/courses';
import { ICourse } from './types';
import programmingImg from './images/programming.png';
import chessImg from './images/chess.png';

export const CoursesList: ICourse[] = [
  {
    id: CourseIds.PROGRAMMING,
    name: 'Программирование',
    age: '( 6 - 16 лет )',
    img: programmingImg,
    active: true,
    features: [
      'определим уровень и цели',
      'дадим честную обратную связь',
      'ребенок сделает свой первый проект',
    ],
  },
  {
    id: CourseIds.CHESS,
    name: 'Шахматы',
    age: '( 6 - 16 лет )',
    img: chessImg,
    active: true,
    features: [
      'определим уровень и цели',
      'дадим честную обратную связь',
      'составим план обучения',
    ],
  },
];
