import React from 'react';
import { CourseCard } from './CourseCard';
import { CoursesList } from './constants';
import { CourseIds } from 'constants/courses';

interface IProps {
  onLeadModalOpen: (course: CourseIds) => void;
}

export const CoursesBlock = ({ onLeadModalOpen }: IProps) => (
  <section className="courses-block container">
    <h2 className="title">Запись на пробные занятия</h2>
    <div className="course-list">
      {CoursesList.map((course, index) => (
        <div key={index} className="course-item">
          <CourseCard
            onActionBtnClick={() => onLeadModalOpen(course.id)}
            {...course}
          />
        </div>
      ))}
    </div>
  </section>
);
