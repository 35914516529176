import Button from 'react-bootstrap/Button';
import confirmImg from './images/confirm.svg';
import React from 'react';
import { ICourse } from './types';

interface IProps {
  onActionBtnClick: () => void;
}

export const CourseCard = ({
  name,
  age,
  img,
  features,
  active,
  onActionBtnClick,
}: ICourse & IProps) => (
  <div className="course-card">
    <img src={img} className="course-img" alt={name} />
    <div className="course-details">
      <h4 className="course-name">
        {name} <small>{age}</small>
      </h4>
      {active && (
        <div className="course-features">
          <div className="features-title">Что включено:</div>
          <ul className="feature-list">
            {features.map((feature, index) => (
              <li key={index} className="feature-item">
                <img src={confirmImg} alt="Подтвердить" />
                {feature}
              </li>
            ))}
          </ul>
        </div>
      )}
      {!active && <div className="course-unavailable">Совсем скоро!</div>}
    </div>
    <div className="action-btn-wrapper">
      {!active && <div className="course-new">NEW!</div>}
      <Button
        onClick={onActionBtnClick}
        variant="secondary"
        size="lg"
        className="action-btn btn-shine"
      >
        Записаться
      </Button>
    </div>
  </div>
);
