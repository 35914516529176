import React from 'react';
import Button from 'react-bootstrap/Button';
import { BackgroundImg } from './BackgroundImg';

export const MainBlock = () => {
  const onClick = React.useCallback(() => {
    (document.querySelector('.courses-block') as HTMLDivElement).scrollIntoView(
      {
        behavior: 'smooth',
      },
    );
  }, []);

  return (
    <section className="main-block container">
      <div className="background-top" />
      <div className="main-proposition">
        <h1 className="title">
          Здравствуйте! UP! School приглашает на бесплатные пробные занятия
        </h1>
        <div className="subtitle">
          Определим уровень вашего ребёнка в выбранном предмете, а вы поймете,
          подходит ли для вас наша онлайн-школа
        </div>
        <Button onClick={onClick} variant="secondary" size="lg">
          Выбрать предмет
        </Button>
      </div>
      <BackgroundImg />
    </section>
  );
};
